import React from 'react'
import { Heading, Flex, Box, Container, Text, List, ListItem, UnorderedList } from '@chakra-ui/react'
import HookForm from '../components/form2U'
import Header from '../components/header'

export default function Home() {
        const Project = 'SC1003T'

        return (
                <>
                        <Header />
                        <Heading as="h1" fontSize="3xl" m="4" align="center">
                                {Project} Self Tape Submissions - TEST
                        </Heading>
                        <Container mb={8} padding={1}>
                                <Heading as="h2" fontSize="2xl" m="2" align="center">
                                        Are you aged 17 or over?
                                        <br />
                                        Do you have any questions you've always wanted to ask the police?
                                </Heading>
                                <Box>
                                        <Text mb={2}>
                                                We would be interested to hear your thoughts on the police in England
                                                and Wales:
                                        </Text>
                                        <UnorderedList mb={2}>
                                                <ListItem> What have you always wondered about what they do?</ListItem>
                                                <ListItem>
                                                        Have you ever considered a career in the police? If not, why
                                                        not?
                                                </ListItem>
                                        </UnorderedList>
                                        <Text mb={2}>
                                                We are making a series of films with C4 and the police and we want to
                                                speak to you about your views of the police
                                        </Text>
                                        <Text mb={1}>If this sounds of interest please fill in the form below.</Text>
                                </Box>
                        </Container>
                        <Flex as="main" width="full" align="center" flexDirection="column" justifyContent="center">
                                <HookForm project={Project} />
                        </Flex>
                </>
        )
}
